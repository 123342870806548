'use client'

import { useState } from 'react'
import { useAuthModal } from '@/app/authModalProvider'
import { login } from '@/lib/endpoints'
import { useAppDispatch } from '@/lib/hooks'
import { setUser } from '@/redux/slices/userSlice'
import { clearLocationHistory } from '@/redux/slices/locationSlice'
import { toast } from 'sonner'

import { Mail, Lock } from 'lucide-react'
import Input from '../myInput'
import { Label } from '../label'
import { Button } from '../buttons/button'

export function Login() {
  const { openRegisterModal, closeModal } = useAuthModal()
  const dispatch = useAppDispatch()
  const [loading, setLoading] = useState(false)
  const [form, setForm] = useState({
    email: '',
    password: ''
  })

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setForm(prevState => ({ ...prevState, [name]: value }))
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setLoading(true)

    const formData = new FormData()
    formData.append('email', form.email)
    formData.append('password', form.password)

    try {
      const response = await fetch(login, {
        method: 'POST',
        body: formData,
      })

      const data = await response.json()

      if (data.error) {
        toast.error(`Login failed: ${data.error_msg}`)
        console.error('Login failed:', data.error_msg)
      } else if (data.user) {
        dispatch(clearLocationHistory())
        const { password, encrypted_password, salt, location, ...safeUserData } = data.user
        dispatch(setUser(safeUserData))
        toast.success('Login successful!')
        closeModal()
        window.location.reload()
      }
    } catch (error) {
      console.error('Error:', error)
      toast.error('An unexpected error occurred. Please try again.')
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className='w-full max-w-md mx-auto'>
      <h3 className='text-2xl font-bold text-center mb-2'>Welcome Back!</h3>
      <h4 className='text-sm text-faded600 font-regular text-center mb-4'>Enter your email and password to continue.</h4>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div className="space-y-2">
          <Label htmlFor="email">Email</Label>
          <div className="relative">
            <Mail className="absolute left-3 top-1/2 transform -translate-y-1/2 text-muted-foreground" size={18} />
            <Input
              type="email"
              id="email"
              name="email"
              required
              placeholder="Enter your email"
              value={form.email}
              onChange={handleChange}
              className="pl-10"
            />
          </div>
        </div>
        <div className="space-y-2">
          <Label htmlFor="password">Password</Label>
          <div className="relative">
            <Lock className="absolute left-3 top-1/2 transform -translate-y-1/2 text-muted-foreground" size={18} />
            <Input
              type="password"
              id="password"
              name="password"
              required
              placeholder="Enter your password"
              value={form.password}
              onChange={handleChange}
              className="pl-10"
            />
          </div>
        </div>
        <Button type="submit" className="w-full" disabled={loading}>
          {loading ? 'Signing in...' : 'Sign in'}
        </Button>
      </form>
      <p className='mt-4 text-sm text-center text-muted-foreground'>
        Don&apos;t have an account yet?{' '}
        <Button variant="link" onClick={openRegisterModal} className="p-0 font-semibold">
          Register for free
        </Button>
      </p>
    </div>
  )
}