import React, { useState, useEffect } from 'react';
import { GoogleMap, DirectionsService, DirectionsRenderer, useLoadScript, Marker } from '@react-google-maps/api';
import Loader from '@/components/shared/loader';
import { cn } from "@/lib/utils";

interface Coordinates {
    lat: number;
    lng: number;
}

const DirectionsMap = ({ source, destination, userClass }: { source: Coordinates; destination: Coordinates; userClass?: string }) => {
    const [directions, setDirections] = useState<google.maps.DirectionsResult | null>(null);
    const [startLocation, setStartLocation] = useState<Coordinates>(source);
    const [endLocation, setEndLocation] = useState<Coordinates>(destination);

    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: process.env.NEXT_PUBLIC_MAPS_API_KEY!,
        libraries: ["places"],
    });

    const mapOptions = {
        mapTypeControl: false,
        streetViewControl: false,
        zoomControl: false,
        fullscreenControl: false,
        disableDefaultUI: true,
    };

    useEffect(() => {
        if (isLoaded) {
            const directionsService = new window.google.maps.DirectionsService();
            directionsService.route(
                {
                    origin: source,
                    destination: destination,
                    travelMode: window.google.maps.TravelMode.DRIVING,
                },
                (result, status) => {
                    if (status === window.google.maps.DirectionsStatus.OK) {
                        setDirections(result);
                        const route = result?.routes[0]?.legs[0];
                        if (route) {
                            setStartLocation({
                                lat: route.start_location.lat(),
                                lng: route.start_location.lng(),
                            });
                            setEndLocation({
                                lat: route.end_location.lat(),
                                lng: route.end_location.lng(),
                            });
                        }
                    } else {
                        console.error(`Error fetching directions: ${status}`);
                    }
                }
            );
        }
    }, [isLoaded, source, destination]);

    if (loadError) return <div className='text-destructive'>Error Loading Maps</div>;
    if (!isLoaded) return <div><Loader/></div>;

    const center = {
        lat: (startLocation.lat + endLocation.lat) / 2,
        lng: (startLocation.lng + endLocation.lng) / 2,
    };

    const containerClassName = cn(
            "relative w-[350px] h-[200px] md:h-[200px] mt-0 flex items-center justify-center rounded-md",
            userClass
        );
    return (
        <div className={containerClassName}>
            <GoogleMap
                mapContainerClassName="w-full h-full rounded-md"
                center={center}
                options={mapOptions}
            >
                {directions && (
                    <DirectionsRenderer
                        directions={directions}
                        options={{
                            suppressMarkers: true,
                              suppressInfoWindows: true,
                        }}
                    />
                )}
                <Marker
                    position={startLocation}
                    icon={{
                        url: "/logos/shop.svg",
                        scaledSize: new window.google.maps.Size(40, 40),
                        labelOrigin: new window.google.maps.Point(20, 50),
                    }}
                />
                <Marker
                    position={endLocation}
                        icon={{
                        url: "/logos/user-destination.svg",
                        scaledSize: new window.google.maps.Size(40, 40),
                        labelOrigin: new window.google.maps.Point(20, 50),
                    }}

                />
            </GoogleMap>
        </div>
    );
};

export default DirectionsMap;
