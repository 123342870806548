'use client'
import Image from 'next/image'
import React, { useEffect, useState } from 'react'
import { CiCircleMinus, CiCirclePlus } from 'react-icons/ci'
import { IoEyeOutline, IoTrashBin } from 'react-icons/io5'
import { FaArrowRight, FaLocationCrosshairs } from "react-icons/fa6";
import { LiaShoppingCartSolid } from 'react-icons/lia'
import { AiOutlineCloseCircle } from 'react-icons/ai'
import { RiCloseFill, RiCoupon3Line } from 'react-icons/ri'
import { MdOutlineKeyboardDoubleArrowRight } from 'react-icons/md'
import { useAppDispatch, useAppSelector } from '@/lib/hooks'
import { decreaseProductQuantity, getCartTotal, increaseProductQuantity, remove } from '@/redux/slices/cartSlice'
import CheckoutButton from '../shared/buttons/checkoutButton'
import { Toaster, toast } from 'sonner'
import Modal from '../shared/wrappers/modal'
import MyPrimaryButton from '../shared/buttons/myPrimaryButton'
import { MinusIcon, PlusIcon, Trash2, Locate } from 'lucide-react'
import {Button} from '@/components/shared/buttons/button'
import { Card } from "@/components/shared/card";
import { ScrollArea } from '@/components/shared/scrollArea';
import { Separator } from '../shared/separator'
import Input from '../shared/myInput'

const MyOrderSideBar = ({ settings }: any) => {
    // console.log("settings : ", settings)
    const dispatch = useAppDispatch()
    //@ts-ignore
    const { cart, totalQuantity, totalAmount } = useAppSelector((state) => state.allCart)
    //@ts-ignore
    const { nearestBranch } = useAppSelector<any>((state) => state.location)
    let delivery_less_3: any, delivery_3_to_5: any;
    if (settings) {
        ({ delivery_less_3, delivery_3_to_5 } = settings)
    }

    const [absoluteSiderbar, setAbsoluteSidebar] = useState(true)
    const [showCart, setShowCart] = useState(false)


    useEffect(() => {
        if (totalQuantity > 0 && !showCart) {
            setShowCart(true)
        }
        if (totalQuantity === 0 && showCart) {
            setShowCart(false)
        }
    }, [totalQuantity])

    let deliveryCharges = 0
    if (nearestBranch?.distance < 3000) {
        deliveryCharges = parseInt(delivery_less_3)
    }
    else if (nearestBranch?.distance >= 3000 && nearestBranch.distance <= 5000) {
        deliveryCharges = parseInt(delivery_3_to_5)
    }

    const increaseQuantity = (id: number) => {
        dispatch(increaseProductQuantity(id))
    }

    const decreaseQuantity = (id: number) => {
        dispatch(decreaseProductQuantity(id))
    }

    const removeProductFromCart = (id: number) => {
        dispatch(remove(id))
        toast.success('Product successfully removed')
    }

    // calculate the total amount and total quantitiy of items in cart
    dispatch(getCartTotal())


    return (
        <section>
            <Toaster position="top-center" richColors />

            <div
                onClick={() => setShowCart(!showCart)}
                className={`bg-primary flex justify-end items-center rounded-l-lg px-2 py-3 absolute top-2 right-0 text-white font-bold z-50 cursor-pointer  ${showCart ? "hidden" : ""}`}
            >
                <LiaShoppingCartSolid className='size-7' />
                {cart.length > 0 && <p className='absolute top-3 bg-red-500 size-4 flex center text-xs text-center rounded-full text-white'>{cart.length}</p>}
            </div>

            <div className={`${absoluteSiderbar ? "max-lg:absolute " : ""}  max-lg:top-0 max-lg:right-0 z-50 h-screen bg-gradient-to-tr from-gray-50 to-blue-50 overflow-y-scroll scrollbar-hidden  flex flex-col justify-between duration-100 ${!showCart ? "w-0 opacity-0 duration-200" : " md:w-80 w-full py-5 px-2 duration-200 opacity-100"}`}>

                <div>

                    <div className=' flex gap-x-6 items-center'>
                        <div
                            onClick={() => setShowCart(!showCart)}
                            className='size-14 -ml-5 bg-primary text-white rounded-lg px-0.5 py-2 flex center cursor-pointer'>
                            <MdOutlineKeyboardDoubleArrowRight className={'size-8'} />
                        </div>
                        <h3 className='font-bold text-lg md:text-xl mb-2 mt-2'>My Cart</h3>
                    </div>


                    {nearestBranch?.name &&
                    <Card className='text-muted-foreground rounded-lg p-2 my-4 flex items-center gap-2'>
                    <Locate className='size-5' />
                        <p className='font-bold  md:text-base flex flex-col gap-x-2'> {nearestBranch?.name} <p className='text-xs font-normal'>{nearestBranch?.address}</p></p>

                    </Card>}


                    {cart.length <= 0 ? (
                        <div className='h-full flex flex-col items-center gap-4 justify-center'>
                        <Image src='/images/empty-cart.svg' alt='empty cart' width={150} height={150} className='mx-auto pointer-events-none' />
                            <p className='font-medium text-muted-foreground  text-lg text-center'>Your Cart is Empty</p>
                        </div>
                    ) : (
                        <>

                          <ScrollArea className=' h-[450px] py-4'>

                                {/* product card */}
                                {cart?.map((product: any, index: number) => (
                                    <div key={index} className='w-full flex items-center justify-between gap-4 mb-6'>
                                        <div className="flex-shrink-0 h-full w-28">
                                            <Image src={product.image!} unoptimized alt="food image" height={200} width={200} className='h-16 w-24 md:h-20 md:w-28 object-cover rounded-lg' />
                                        </div>

                                        <div className='w-full mr-4 flex flex-col gap-2 '>
                                            <div className='flex flex-col justify-between items-start'>
                                                <p className='font-medium text-start text-sm  md:text-sm leading-tight break-words'>{product.name}</p>
                                                <p className='text-muted-foreground  font-normal text-base text-sm flex-shrink-0'>Rs {product.totalPrice * product?.quantity!}</p>
                                            </div>
                                            {/* variations */}

                                            {product.variations?.map((variation: Variation) => (
                                                <div key={variation?.id} className='flex justify-between items-start font-normal'>
                                                    <p className='text-muted-foreground  text-sm flex-grow'>{variation?.name}</p>
                                                      <p className='text-muted-foreground  text-sm whitespace-nowrap'>+Rs {variation?.price}</p>
                                                </div>
                                            ))}
                                            <div className='w-full flex justify-between items-center '>
                                                <div className=' flex items-center gap-x-2 xs:gap-x-2'>
                                                    <Button className='h-6 w-6 p-0 ' variant={"outline"} onClick={() => decreaseQuantity(index)}>
                                                <MinusIcon className='text-muted-foreground  w-4 h-4'/>
                                                </Button>
                                                    <p className='font-semibold text-sm select-none' unselectable='on'>{product.quantity}</p>
                                                    <Button className='h-6 w-6 p-0 ' variant={"outline"} onClick={() => increaseQuantity(index)}> <PlusIcon className='text-muted-foreground  w-4 h-4'/> </Button>
                                                </div>
                                                <Trash2 className='size-5 text-destructive font-bold  cursor-pointer' onClick={() => removeProductFromCart(index)} />
                                            </div>
                                        </div>
                                    </div>
                                ))}

                            </ScrollArea>


                        </>
                    )}
                </div>



                <div>
                  <div>
                      <form className='flex  gap-x-2 my-3' action="" >
                              <Input type="text" className='w-full' placeholder='Enter voucher' id="coupon" />
                              <Button className='' variant={"outline"} size={"icon"}> <RiCoupon3Line className='size-6 text-primary' /> </Button>
                      </form>
                  </div>
                    <Separator className='"my-4' />
                    {cart.length > 0 &&
                        <div className='px-2'>
                            <div className='flex flex-col py-2 gap-2 text-muted-foreground text-sm'>
                                <div className='flex justify-between itemscenter '>
                                    <p>Sub Total</p>
                                    <p>Rs {totalAmount}</p>
                                </div>
                                <div className='flex justify-between itemscenter'>
                                    <p>Delivery Fee</p>
                                    <p>Rs {deliveryCharges}</p>
                                </div>
                            </div>
                            <Separator className='"my-4' />

                            <div className='flex justify-between items-center  my-3 font-bold text-lg'>
                                <p className=''>Total</p>
                                <p className=''>Rs {totalAmount + deliveryCharges}</p>
                            </div>

                        </div>


                    }
                    {/* checkout button */}
                    <div className=''>
                        <CheckoutButton cart={cart} totalAmount={totalAmount} totalQuantity={totalQuantity} deliveryCharges={deliveryCharges} setAbsoluteSidebar={setAbsoluteSidebar} />
                    </div>
                </div>

            </div>

        </section>
    )
}

export default MyOrderSideBar
