//@ts-nocheck
'use client'
import React, { useEffect, useState } from 'react';
import { FaArrowRight } from 'react-icons/fa6';
import Modal from '../wrappers/modal';
import { useAppDispatch, useAppSelector } from '@/lib/hooks';
import { placeOrder } from '@/lib/endpoints';
import Image from 'next/image';
import { emptyCart } from '@/redux/slices/cartSlice';
import { useAuthModal } from '@/app/authModalProvider';
import Lottie from "lottie-react";
import Success from "@/components/animations/success_animation.json"
import { Toaster, toast } from 'sonner';
import MyPrimaryButton from '@/components/shared/buttons/myPrimaryButton';
import Link from 'next/link';
import Input from '../myInput';
import TextArea from '@/components/shared/textArea';
import AuthModal from '../wrappers/authModal';
import { setGuestPhoneNumber } from '@/redux/slices/guestOrderSlice';
import LocationComponent from '@/components/views/location';
import { Button } from './button';
import { User, Lock, Locate, Phone } from 'lucide-react';
import Map from '@/components/shared/map';
import { addSavedAddress, setCurrentAddress, setNearestBranch } from '@/redux/slices/locationSlice';

import { v4 as uuidv4 } from 'uuid'; // Import the uuid library
import OrderTrackingMap from '../orderTrackingMap';
import { getNearestBranch } from '@/lib/apiRequests';
import DeliveryMapPolylines from "@/components/shared/maps/DeliveryMapPolylines";
import { Card } from '../card';
import OrderSummary from '../orderSummary';
import InitialModal from '@/components/shared/initialModal';
import { Separator } from '../separator';

interface Props {
  cart: CartState['cart']
  totalAmount: CartState['totalAmount']
  totalQuantity: CartState['totalQuantity']
  deliveryCharges: number
  setAbsoluteSidebar: any
}
interface BranchCoord {
  lat: number;
  lng: number;
}
function convertToBranchCoord(location: string): BranchCoord | null {
  if (!location) return null;
  const [lat, lng] = location.split(',').map(coord => parseFloat(coord.trim()));
  return isNaN(lat) || isNaN(lng) ? null : { lat, lng };
}
const CheckoutButton = ({ cart, deliveryCharges, totalAmount, totalQuantity, setAbsoluteSidebar }: Props) => {
  const [apiResponse, setApiResponse] = useState(null);
  const [showOrderSummary, setShowOrderSummary] = useState(false)
  const { modalType, openLoginModal, openRegisterModal, closeModal } = useAuthModal();
  const [showSuccessMessage, setShowSuccessMessage] = useState(false)

  const [showSelectionModal, setshowSelectionModal] = useState(false)
  const [orderAsGuest, setOrderAsGuest] = useState(false)
  const [guestForm, setGuestForm] = useState({
    name: "",
    phoneNumber: "",
    address: ""
  })
  // @ts-ignore
  const currentAddress = useAppSelector<any>((state) => state.location.currentAddress)
  // @ts-ignore
  const { nearestBranch } = useAppSelector((state) => state.location)
  // @ts-ignore
  const { user } = useAppSelector((state) => state.user)
  const dispatch = useAppDispatch()
  // @ts-ignore
  const branchCoord: BranchCoord = nearestBranch ? convertToBranchCoord(nearestBranch.location) : null;


  // @ts-ignore
  const initialLocation = useAppSelector((state) => state.location.initialLocation);
  const [mapAddress, setMapAddress] = useState(initialLocation?.address ?? "");
  // @ts-ignore
  const [mapLocation, setMapLocation] = useState({
    lat: initialLocation?.location?.latitude, lng: initialLocation?.location?.longitude
  } || null);
  const [mapCity, setMapCity] = useState("");

  const products = cart.map((cartItem) => {
    return {
      // @ts-ignore
      product_id: parseInt(cartItem.id),
      price: parseInt(cartItem.totalPrice),
      quantity: cartItem.quantity,
      variations: cartItem.variationIds!.join(', ')
    }
  })



  const confirmOrder = async (e?: any) => {
    if (e) e.preventDefault();

    const userLocation = currentAddress?.location;
    const body = user ? {
      name: user?.name,
      mobile_number: `${user?.phone}`,
      user_email: user?.email,
      user_id: user?.id,
      customer_address_latitude: `${userLocation?.latitude}`,
      customer_address_longitude: `${userLocation?.longitude}`,
      branch_id: parseInt(nearestBranch.id),
      description: "This is the description",
      sec_mobile: "",
      address: currentAddress?.address,
      gps_address: currentAddress?.address,
      previous_order_id: 0,
      distance: 5,
      delivery_charges: deliveryCharges,
      order_channel: "DELIVERY",
      payment_method: "Cash",
      coupen_key: "",
      wallet_amount: 0,
      user_wallet: 0,
      products,
    } : {
      name: guestForm?.name,
      mobile_number: guestForm.phoneNumber,
      address: currentAddress?.address,
      user_email: "",
      user_id: "",
      customer_address_latitude: `${mapLocation.lat}`,
      customer_address_longitude: `${mapLocation.lng}`,
      branch_id: parseInt(nearestBranch.id),
      description: "",
      sec_mobile: "",
      gps_address: currentAddress?.address,
      previous_order_id: 0,
      distance: 5,
      delivery_charges: deliveryCharges,
      order_channel: "DELIVERY",
      payment_method: "Cash",
      coupen_key: "",
      wallet_amount: 0,
      user_wallet: 0,
      products,
    }

    // console.log("this is body: ", body)
    try {
      const response = await fetch(placeOrder, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      });

      const data = await response.json();

      if (data.error) {
        // Display toast for order placement failed with error message
        toast.error(`Order placement failed: ${data.error_msg}`);
        console.error('Order placement failed:', data.error_msg);
      } else {
        setApiResponse(data);
        console.log('Order placed successfully:', data);

        // Dispatch the emptyCart action to clear the cart
        dispatch(emptyCart());

        if (!user) {
          // dispatch phoneNumber to set the guest
          dispatch(setGuestPhoneNumber(guestForm.phoneNumber))
        }

        // Close the dialog box
        closeSummaryModal();
        // empty the fields of guest form
        setGuestForm({
          name: "",
          phoneNumber: "",
          address: ""
        })

        openSuccessMessageModal()
        // Display toast for successful order placement
        toast.success('Order placed successfully!');


      }
    } catch (error) {
      console.error('Error placing order:', error);
      toast.error('An unexpected error occurred while placing the order. Please try again.');
    }
  };



  const closeSummaryModal = () => {
    setAbsoluteSidebar(true);
    setShowOrderSummary(false);
  };

  const openSuccessMessageModal = () => {
    setAbsoluteSidebar(false);
    setShowSuccessMessage(true);
  };

  const closeSuccessMessageModal = () => {
    setAbsoluteSidebar(true);
    setShowSuccessMessage(false);
  };

  const closeSelectionModal = () => {
    setAbsoluteSidebar(true);
    setshowSelectionModal(false);
  };

  const openSelectionModal = () => {
    setAbsoluteSidebar(false);
    setshowSelectionModal(true);
  };

  const openGuestForm = () => {
    setAbsoluteSidebar(false);
    setOrderAsGuest(true);
  };

  const closeGuestForm = () => {
    setAbsoluteSidebar(true);
    setOrderAsGuest(false);
  };


  const handleClick = () => {
    if (user != null) {
      setAbsoluteSidebar(false)
      setShowOrderSummary(true)
    } else if (!user || user == null) {
      openSelectionModal()
    }
  }

  const handleGuestFormChange = (e: any) => {
    const { name, value } = e.target;
    setGuestForm(prevState => ({
      ...prevState,
      [name]: value
    }));
  }

  const handleGuestFormSubmit = (e: any) => {
    e.preventDefault()
    const uniqueId = uuidv4(); // Generate a unique ID

    // dispatch(addSavedAddress({
    //   id: uniqueId,
    //   location: {
    //     latitude: mapLocation?.lat,
    //     longitude: mapLocation?.lng
    //   },
    //   address: guestForm.address,
    //   gpsAddress: mapAddress,
    //   title: guestForm.address,
    //   city: mapCity
    // }));

    // dispatch(setCurrentAddress({
    //   id: uniqueId,
    //   location: {
    //     latitude: mapLocation?.lat,
    //     longitude: mapLocation?.lng
    //   },
    //   address: guestForm.address,
    //   gpsAddress: mapAddress,
    //   title: guestForm.address,
    //   city: mapCity
    // }))
    getNearestBranchData();
    closeGuestForm()
    setAbsoluteSidebar(false);
    console.log("branch", nearestBranch)
    setShowOrderSummary(true)
  }
  //  Fetch nearest branch
  async function getNearestBranchData() {
    let formdata = new FormData();
    formdata.append("lat", currentAddress.location?.latitude.toString());
    formdata.append("lon", currentAddress.location?.longitude.toString());

    const branchResponse = await getNearestBranch(formdata);

    if (branchResponse.error) {
      console.error('Error getting nearest branch:', branchResponse.error_msg);
      toast.error(branchResponse.error_msg);
      return
    } else {
      const nearestBranch = branchResponse?.records
      dispatch(setNearestBranch(nearestBranch));
    }
  }
  useEffect(() => {
    if (currentAddress) {
      console.log("currentAddress", currentAddress)
      getNearestBranchData()
    }
  }, [currentAddress])

  // }, [branchCoord, currentAddress, nearestBranch])
  return (
    <div className=''>
      <Toaster richColors position="top-center" />
      <Button
        onClick={handleClick}
        disabled={cart.length <= 0}
        className='flex justify-center items-center gap-2 text-sm w-full bg-primary  text-white disabled:cursor-not-allowed'>
        Order and Checkout <FaArrowRight />
      </Button>

      {
        // Order Summary
        !showSuccessMessage && showOrderSummary ? (
          <Modal closeModal={closeSummaryModal} title='Checkout'>
            <div className="w-full md:w-[28rem] h-full  flex flex-col gap-4">
                    {/* <div className="flex-1 space-y-4 p-2 md:p-0 "> */}
                      <Card className="w-full p-4 space-y-2">
                        <div className="flex items-center space-x-2">
                          <Image src="/logos/shop.svg" alt="Pickup location" height={24} width={24} />
                          <h3 className="text-base font-bold">Pickup Location</h3>
                        </div>
                        <p className="text-muted-foreground md:text-base">{nearestBranch?.name}</p>
                      </Card>

                      <Card className="w-full p-4 space-y-2">
                        <div className="flex items-center space-x-3">
                          <Image src="/logos/user-destination.svg" alt="Delivery location" height={24} width={24} />
                          <h3 className="text-base font-bold">Delivery Address</h3>
                        </div>
                        <p className="text-muted-foreground md:text-base">{currentAddress?.address}</p>
                      </Card>

                      <OrderSummary
                        cart={cart}
                        deliveryCharges={deliveryCharges}
                        totalAmount={totalAmount}
                        totalQuantity={totalQuantity}
                      />
                       <Button
                        onClick={confirmOrder}
                        className="w-full text-white font-bold text-lg transition-colors"
                      >
                        Confirm Order
                      </Button>
                    {/* </div> */}

                    {/* <div className="flex-1 flex flex-col-reverse justify-between md:flex-col space-y-2 "> */}
                      {/* <div className="w-full flex flex-col justify-between items-start  m-2 md:m-0 border rounded-lg">
                        <h3 className="text-lg font-semibold p-4">Delivery Route</h3>
                        <div className='map-poly w-full max-w-full rounded-lg'>
                          {branchCoord && currentAddress?.location && (
                            <DeliveryMapPolylines
                              source={{ lat: branchCoord.lat, lng: branchCoord.lng }}
                              destination={{
                                lat: currentAddress.location.latitude,
                                lng: currentAddress.location.longitude
                              }}
                              userClass='md:h-[400px] lg:w-[500px]'
                            />
                          )}
                        </div>
                        </div> */}

                     
                    {/* </div> */}
                  </div>
          </Modal>
        )
          // : showSuccessMessage && !showOrderSummary ? <Modal closeModal={closeSuccessMessageModal}>
          //     <div className='mx-auto size-72 flex center'>
          //       <Lottie animationData={Success} loop={false} />
          //     </div>
          //     <p className='font-bold text-lg my-5'>Your order has been placed successfully.</p>
          //     <Link href={'/myOrders'} className='flex center mb-3'>
          //       <MyPrimaryButton text='Go to My Orders' onClickFunction={closeSuccessMessageModal} />
          //     </Link>
          // </Modal>
          : showSelectionModal ? (
            <div className=''>
              <Modal closeModal={closeSelectionModal} title='Checkout'>
                <div className="space-y-6 p-4 md:p-0">
                        <div className="text-center">
                          <h2 className="text-lg font-semibold text-foreground">You are not currently Logged In</h2>
                          <p className="text-sm text-muted-foreground mt-2">Please choose an option to continue</p>
                        </div>

                        <div className="space-y-4">
                          <Button
                            className="w-full font-bold"
                            onClick={() => {
                              closeSelectionModal()
                              openLoginModal()
                            }}
                          >
                            <Lock className="mr-2 h-4 w-4" />
                            Login
                          </Button>

                          <div className="flex items-center justify-center">
                            <Separator className="flex-grow" />
                            <span className="px-3 text-sm text-muted-foreground">or</span>
                            <Separator className="flex-grow" />
                          </div>

                          <Button
                            variant="outline"
                            className="w-full font-bold"
                            onClick={() => {
                              closeSelectionModal()
                              openGuestForm()
                            }}
                          >
                            <User className="mr-2 h-4 w-4" />
                            Order as a guest
                          </Button>
                        </div>
                      </div>
              </Modal>
            </div>
          ) : orderAsGuest ? (
            <>
              <Modal closeModal={closeGuestForm} title='Shipping Details'>
                <div className='h-full md:w-full mx-auto grid grid-cols-1 md:grid-cols-2 gap-6  text-foreground'>
                  <div className='w-full md:w-1/2 flex flex-col justify-between items-start gap-2'>
                <div className='w-full flex flex-col gap-2'>
                  <p className='text-xs text-faded600 '>Select Address</p>
                  <InitialModal hideModal={true} />
                </div>
                    <div className='map-poly w-full max-w-full rounded-lg'>
                      {branchCoord && currentAddress?.location && (
                        <DeliveryMapPolylines
                          source={{ lat: branchCoord.lat, lng: branchCoord.lng }}
                          destination={{
                            lat: currentAddress.location.latitude,
                            lng: currentAddress.location.longitude
                          }}
                          userClass='md:h-[300px] lg:w-[500px]'
                        />
                      )}
                    </div>
                  </div>
                  <div className='flex flex-col items-center justify-between'>
                  <form onSubmit={(e) => handleGuestFormSubmit(e)} className=' w-full  flex flex-col justify-between gap-2'>
                    <div className="space-y-2">
                                <label htmlFor="name" className="text-sm font-medium text-muted-foreground">Name</label>
                                <div className="relative">
                                  <User className="absolute left-3 top-1/2 transform -translate-y-1/2 text-primary" size={20} />
                                  <Input
                                    type="text"
                                    name="name"
                                    id="name"
                                    required
                                    placeholder="Name"
                                    value={guestForm.name}
                                    onChange={handleGuestFormChange}
                                    className="pl-10"
                                  />
                                </div>
                              </div>
                                <div className="space-y-2">
                                            <label htmlFor="phoneNumber" className="text-sm font-medium text-muted-foreground">Phone Number</label>
                                            <div className="relative">
                                              <Phone className="absolute left-3 top-1/2 transform -translate-y-1/2 text-primary" size={20} />
                                              <Input
                                                type="tel"
                                                name="phoneNumber"
                                                id="phoneNumber"
                                                pattern="\+92[0-9]{10}"
                                                required
                                                placeholder="+92 301 234567"
                                                value={guestForm.phoneNumber}
                                                onChange={handleGuestFormChange}
                                                className="pl-10"
                                              />
                                            </div>
                                          </div>
                                            <div className="space-y-2">
                                                        <label htmlFor="address" className="text-sm font-medium text-muted-foreground">Order Notes (optional)</label>
                                                        <TextArea
                                                          name="address"
                                                          id="address"
                                                          placeholder="Any additional notes for the rider?"
                                                          value={guestForm.address}
                                                          onChange={handleGuestFormChange}
                                                          className="min-h-[100px]"
                                                        />
                                                      </div>


                    <div className='flex items-center justify-center mt-2 '>
                      <Button
                        type='submit'
                        className='bg-primary  text-white font-bold w-full '
                      >
                        Order as guest
                      </Button>
                    </div>
                  </form >
                    <div>
                    <p className='text-xs text-faded  p-2'>Order using an Account? <Button className='text-primary font-semibold text-xs' size={"icon"} variant={"link"} onClick={() => {
                      closeGuestForm()
                      openLoginModal()
                    }}>Login</Button></p>
                  </div>

                  </div>
                </div>
              </Modal>
            </>
          ) : null
      }

    </div>
  );
};

export default CheckoutButton;
