//@ts-nocheck
import { configureStore } from '@reduxjs/toolkit'
import cartReducer from "../slices/cartSlice"
import locationReducer from '../slices/locationSlice'
import userReducer from '../slices/userSlice'
import guestReducer from '../slices/guestOrderSlice'
import storage from 'redux-persist/lib/storage';
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import {thunk} from 'redux-thunk';



const reducers = combineReducers({
  allCart: cartReducer,
  location: locationReducer,
  user : userReducer,
  guest : guestReducer
});

const persistConfig = {
  key: 'root',
  storage,
}

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  // @ts-ignore
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk),
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch