'use client'

import { useAuthModal } from '@/app/authModalProvider'
import { Dialog, DialogContent } from '../wrappers/dialog'
import { Login } from './login'
import { Register } from './register'


export function AuthDialog() {
  const { modalType, closeModal } = useAuthModal()

  return (
    <Dialog open={modalType !== null} onOpenChange={(open) => !open && closeModal()}>
      <DialogContent className="sm:max-w-[425px]">
        {modalType === 'login' && <Login />}
        {modalType === 'register' && <Register />}
      </DialogContent>
    </Dialog>
  )
}