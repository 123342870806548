'use client'

import { useState } from 'react'
import { useAuthModal } from '@/app/authModalProvider'
import { register } from '@/lib/endpoints'
import Link from 'next/link'
import { toast } from 'sonner'

import { Mail, Lock, User, Phone } from 'lucide-react'
import { Label } from '../label'
import Input from '../myInput'
import { Button } from '../buttons/button'

export function Register() {
  const { openLoginModal, closeModal } = useAuthModal()
  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(false)
  const [loading, setLoading] = useState(false)
  const [form, setForm] = useState({
    name: '',
    email: '',
    phoneNumber: '',
    password: ''
  })

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setForm(prevState => ({
      ...prevState,
      [name]: value
    }))
    if (name === 'phoneNumber') {
      const phoneNumberPattern = /^\+92[0-9]{10}$/
      setIsPhoneNumberValid(phoneNumberPattern.test(value))
    }
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setLoading(true)

    const formData = new FormData()
    formData.append('name', form.name)
    formData.append('email', form.email)
    formData.append('password', form.password)
    formData.append('phone', form.phoneNumber.replace(/-/g, ''))

    try {
      const response = await fetch(register, {
        method: 'POST',
        body: formData,
      })

      const data = await response.json()

      if (data.error) {
        toast.error(`Registration failed: ${data.error_msg}`)
        console.error('Registration failed:', data.error_msg)
      } else {
        toast.success('Registration successful! You can now login')
        closeModal()
      }
    } catch (error) {
      console.error('Network error:', error)
      toast.error('An unexpected error occurred. Please try again.')
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className='w-full max-w-md mx-auto'>
      <h3 className='text-2xl font-bold text-center mb-2'>Create Your Account</h3>
      <form onSubmit={handleSubmit} className='space-y-4'>
        <div className="space-y-2">
          <Label htmlFor="name">Name</Label>
          <div className="relative">
            <User className="absolute left-3 top-1/2 transform -translate-y-1/2 text-muted-foreground" size={18} />
            <Input
              type="text"
              name="name"
              id="name"
              required
              placeholder="Enter your name"
              value={form.name}
              onChange={handleChange}
              className="pl-10"
            />
          </div>
        </div>

        <div className="space-y-2">
          <Label htmlFor="email">Email</Label>
          <div className="relative">
            <Mail className="absolute left-3 top-1/2 transform -translate-y-1/2 text-muted-foreground" size={18} />
            <Input
              type="email"
              name="email"
              id="email"
              required
              placeholder="Enter your email"
              value={form.email}
              onChange={handleChange}
              className="pl-10"
            />
          </div>
        </div>

        <div className="space-y-2">
          <Label htmlFor="phoneNumber">Phone</Label>
          <div className="relative">
            <Phone className="absolute left-3 top-1/2 transform -translate-y-1/2 text-muted-foreground" size={18} />
            <Input
              type="tel"
              name="phoneNumber"
              id="phoneNumber"
              pattern="\+92[0-9]{10}"
              required
              placeholder="+92 301 234567"
              value={form.phoneNumber}
              onChange={handleChange}
              className="pl-10"
            />
          </div>
        </div>

        <div className="space-y-2">
          <Label htmlFor="password">Password</Label>
          <div className="relative">
            <Lock className="absolute left-3 top-1/2 transform -translate-y-1/2 text-muted-foreground" size={18} />
            <Input
              type="password"
              name="password"
              id="password"
              required
              placeholder="Enter your password"
              value={form.password}
              onChange={handleChange}
              className="pl-10"
            />
          </div>
        </div>

        <Button
          type='submit'
          disabled={loading || !isPhoneNumberValid}
          className='w-full'
        >
          {loading ? 'Registering...' : 'Register'}
        </Button>
      </form>

      <p className='max-w-80 text-xs text-muted-foreground text-center mx-auto my-4'>
        By clicking register you agree to our{' '}
        <Link href="/" className='underline'>terms and conditions</Link> and{' '}
        <Link href="/" className='underline'>privacy policy</Link>
      </p>

      <p className='text-sm text-center text-faded600'>
        Already have an account?{' '}
        <Button variant="link" onClick={openLoginModal} className='p-0 font-semibold'>
          Login
        </Button>
      </p>
    </div>
  )
}