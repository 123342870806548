import { Card } from "./card";
import { ScrollArea } from "./scrollArea";

interface OrderSummaryProps {
    cart: CartState['cart']
    deliveryCharges: number
    totalAmount: CartState['totalAmount']
    totalQuantity: CartState['totalQuantity']
}

const OrderSummary = ({ cart, deliveryCharges, totalAmount }: OrderSummaryProps) => {
    return (
        <Card className='w-full md:m-x-2 p-4 mb-2'>
            <h3 className='font-bold text-lg text-foreground mb-4'>Order Summary</h3>
            <ScrollArea className='md:h-36 h-28 mb-4'>
                {cart?.map((product, index) => (
                    <div key={index} className='flex flex-col rounded-lg w-full  mb-4 text-muted-foreground '>
                        <div className='w-full flex justify-between items-center '>
                            <p className=' text-sm'>{product?.name}</p>
                            <div className='flex gap-x-4 items-center'>
                                <p className='  select-none text-nowrap text-sm ' unselectable='on'>{product.quantity + " "}x{" "}</p>
                                <p className=' text-sm  '>Rs {product.itemPrice}</p>
                            </div>
                        </div>

                        <div className='max-w-fit self-end '>
                            {/* variations */}
                            <div className='text-xs col-span-3   '>
                                {product.variations?.map((variation: Variation) => (
                                    <div key={variation?.id} className='flex justify-between  gap-x-4'>
                                        <p className='text-muted-foreground'>{variation?.name}</p>
                                        <p className='text-primary'>+Rs {variation?.price}</p>
                                    </div>
                                ))}
                            </div>
                        </div>

                    </div>
                ))}
            </ScrollArea>
            <hr className='w-full' />
            <div className='w-full flex flex-col text-xs  lg:text-sm mt-2 gap-x-2 gap-y-4 relative '>
                <div className='flex justify-between font-semibold'>
                    <p className=' text-muted-foreground text-sm'>Delivery </p>
                      <p className='my-auto text-sm  text-muted-foreground'>Rs {deliveryCharges}</p>
                </div>

                {/* ==============  just for sepration*/}

                <div className='flex justify-between font-bold '>


                    <p className=' text-foreground text-sm md:text-lg'>Total</p>
                    <p className='border-t-2 border-dashed transform translate-y-1/2 '></p>
                    <p className='my-auto md:text-lg text-sm'>Rs {totalAmount + deliveryCharges}</p>
                </div>
            </div>
        </Card>
    )
}

export default OrderSummary;
