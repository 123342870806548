import { createSlice } from '@reduxjs/toolkit';

export const guestOrderSlice = createSlice({
  name: 'guest',
  initialState: {
    phoneNumber: null,
    guestLocation : [{
      location: null,
      address: null,
      nearestBranch: null
    }]
  },
  reducers: {
    setGuestPhoneNumber: (state, action) => {
      state.phoneNumber = action.payload;
    },
    clearGuestPhoneNumber: (state) => {
      state.phoneNumber = null;
    },

  },
});

// Export the actions
export const {
  setGuestPhoneNumber,
  clearGuestPhoneNumber,

} = guestOrderSlice.actions;

// Export the reducer
export default guestOrderSlice.reducer;
